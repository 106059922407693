.btnContainer,
.updateBtnContainer {
  width: 100%;
}

.btnContainer {
  justify-content: flex-end;
  gap: 0.625rem;
}

.updateBtnContainer {
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 10px;
}

.cancelBtn {
  min-width: 5.625rem;
}

.okBtn {
  width: 100%;
  max-width: 312px;
  margin-left: 0 !important;
}

.sub {
  color: var(--secondary-4);
  font-size: 12px;
  font-weight: 500;
}

.advertiserText,
.agencyText {
  display: block;
  max-width: 350px;
}

.title {
  color: var(--primary-6) !important;
  font-size: 1.125rem !important;
  line-height: 1 !important;
}

.noSubtitle {
  margin-bottom: 0 !important;
}

.modal div[class="ant-modal-header"] {
  border-radius: 1.25rem 1.25rem 0 0;
  padding: 1.125rem 1.125rem 0 1.125rem;
  border: none;
  position: relative;
}

.modal div[class="ant-modal-footer"] {
  padding: 0 1.125rem 1.125rem 1.125rem;
}

.modal.settings div[class="ant-modal-footer"] {
  padding: 0 20px 20px 20px;
}
.modal.settings div[class="ant-modal-header"] {
  padding: 20px 20px 0 20px;
  & h4 {
    margin-bottom: 0 !important;
  }
}

.modal div[class="ant-modal-body"] {
  padding: 1.25rem 1.125rem;
}

.modal.products {
  width: 62rem !important;
}

.modal.keywords,
.modal.modal.negative_keywords,
.modal.categories,
.modal.negative_categories {
  width: 61.75rem !important;
}

.modal.update {
  width: 29.25rem !important;

  & > div {
    max-height: 90vh;
    overflow-y: auto;
  }
}

.modal.update_bid {
  width: 29rem !important;
}

.modal.create_agency,
.modal.select_advertiser,
.modal.create_user_marketplace,
.modal.create_user_advertiser,
.modal.update_advertiser,
.modal.update_agency,
.modal.select_user,
.modal.targeting_settings,
.modal.update_transaction,
.modal.select_language {
  width: 25rem !important;
}

.modal.approve_balance,
.modal.approve_coupon {
  width: 28rem !important;
}

.modal.add_user {
  width: 27rem !important;

  & .sub {
    display: inline-block;
    max-width: 330px;
  }
}

.modal.invoice,
.modal.coupons,
.modal.transactions {
  width: 43.125rem !important;
}

.modal.payment_options {
  width: 44rem !important;
}

.modal.select_advertiser .updateBtnContainer {
  margin-top: 5.75rem;
}

.modal.settings {
  width: 34.5rem !important;
}

.modal.campaign_form,
.modal.refund,
.modal.refund_confirm {
  width: 32.75rem !important;
}

.modal.settings div[class="ant-modal-body"] {
  border-top: var(--border-base);
  border-bottom: var(--border-base);
  margin: 1rem 0;
}

.modal.transaction_details {
  width: 26.25rem !important;
  & div[class="ant-tabs-content-holder"] {
    max-height: 300px;
    overflow: auto;
  }
}

.modal.creatives {
  width: 64rem !important;
}

.modal.campaign_form .btnContainer {
  justify-content: space-between;
}

.modal.campaign_form .btn {
  border-radius: 0.625rem;
  min-height: 2.625rem;
}

.modal.campaign_form .okBtn {
  min-width: 21.125rem;
}

.modal.campaign_form .cancelBtn {
  min-width: 8.75rem;
}

.modal.show_vast_tag {
  width: 50rem !important;

  & .okBtn {
    max-width: 90px;
  }
}

.modal.update_dayparting {
  width: 966px !important;

  & .updateBtnContainer {
    justify-content: flex-end;
    gap: 0.625rem;
  }

  & .okBtn {
    max-width: 250px;
  }
}

.modal.reject_creative {
  width: 420px !important;

  & .okBtn {
    width: 100%;
    max-width: 254px;
  }

  & .cancelBtn {
    width: 100%;
    max-width: 120px;
  }
}

.modal.refund,
.modal.confirm_refund {
  & .okBtn {
    width: 100%;
    max-width: 354px;
  }

  & .cancelBtn {
    width: 100%;
    max-width: 120px;
  }
}

.modal.confirm_refund {
  & .okBtn {
    background-color: var(--red-5);

    &:hover,
    &:focus {
      background-color: var(--red-5) !important;
    }
  }
}
